<template>
  <div class="pd">
    <div class="header_wrapper">
      <div class="header_left">
        <div class="logo" @click="$router.push('/')">
          <img height="50px" src="https://fbrj-hz-01.oss-cn-hangzhou.aliyuncs.com/assets/logo.png" alt="logo"/>
        </div>
        <div class="nav">
          <router-link to="/">首页</router-link>
          <router-link to="/fund"> 基金产品</router-link>
          <router-link to="/aboutus"> 关于我们</router-link>
          <router-link to="/joinus"> 加入我们</router-link>
          <router-link to="/contactus"> 联系我们</router-link>
        </div>
      </div>
      <!-- 登录按钮pc -->
      <div class="login" v-if="!hasLogin" @click="$router.push('/login')">
          <img src="https://fbrj-hz-01.oss-cn-hangzhou.aliyuncs.com/assets/user.png">
          <span style="color: white">登录</span>
      </div>
      <!-- 登录后的信息pc -->
      <div v-else style="display: flex;" class="admin_btn">
        <div style="margin-right:100px" v-if="userInfo.role == 'admin'">  
          <span v-if="!isOnManage">
            <router-link to="/manage"> 切换至管理后台 </router-link>
          </span>
          <span v-else>
            <router-link to="/personal/all"> 切换至投资者后台 </router-link>
          </span>
        </div>
        <div class="login_info">
          <img style="width: 30px;border-radius: 30px;vertical-align:middle;margin-right:5px" src="https://fbrj-hz-01.oss-cn-hangzhou.aliyuncs.com/assets/default_pic.png"/>
          <span>{{ username }}</span> 
          <div class="top_bar">
            <div @click="$router.push('/personal/all')"><a href="javascript:;"><span class="text-color">我的基金</span></a></div>
            <div @click="logout"><a href="javascript:;"><span class="text-color">退出登录</span></a></div>
          </div>
        </div>
      </div>
    </div>

    <div class="header_wrapper_m">
      <div class="menu" @click="isClick = !isClick">
        <div class="midcross" :class="{ active: isClick }"></div>
      </div>
      <div style="height: 40px" @click="$router.push('/')">
        <img height="40px" style="margin-right: 6px;vertical-align: initial;" src="https://fbrj-hz-01.oss-cn-hangzhou.aliyuncs.com/assets/logo2.png" alt="logo"/>
      </div>
      <!-- 登录按钮mob -->
      <div class="login_m" @click="$router.push('/login')" v-if="!hasLogin">
        <span>
          <img src="https://fbrj-hz-01.oss-cn-hangzhou.aliyuncs.com/assets/user.png"/>
          <span class="login_btn">登录</span>
        </span>
      </div>
      <!-- 登录后的信息mob -->
      <div class="login_m" v-else style="width: 50px">
        <span @click="toMyFund">
          <img src="https://fbrj-hz-01.oss-cn-hangzhou.aliyuncs.com/assets/user.png"/>
          <span class="login_btn">{{ username }}</span>
        </span>
      </div>
      <div class="menu_list_m" :class="{ active: isClick }">
        <ul class="nav_m">
          <li><router-link to="/">首页</router-link></li>
          <li><router-link to="/aboutus">关于我们</router-link></li>
          <li><router-link to="/fund">基金产品</router-link></li>
          <li><router-link to="/joinus"> 加入我们</router-link></li>
          <li><router-link to="/contactus"> 联系我们</router-link></li>
          <li v-if="!hasLogin"><router-link to="/login">登录</router-link></li>
          <li v-if="hasLogin"><router-link to="/personal/all"> <span style="font-weight:bold;color:black">我的基金</span> </router-link></li>
          <li v-if="hasLogin" @click="logout"><a href="javascript:;" style="font-weight:bold;color:black">退出登录</a></li>
          <li v-if="task_list"><router-link to="/manage/taskmanage">任务管理</router-link></li>
        </ul>
      </div>
    </div>
    <div class="mobile_placeholder"></div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  data() {
    return {
      isClick: false,
      username: "",  // 接口里是real_name
    };
  },
  computed:{
    ...mapState({
      userInfo:(state) => state.userInfo
    }),
    hasLogin(){
      this.username = this.userInfo.real_name
      return this.userInfo.real_name
    },
    isOnManage(){
      return this.$route.path.includes('manage')
    },
    task_list(){
      return this.userInfo.privilege && this.userInfo.privilege.find(p => p.value == 'task_list')
    },    
  },
  methods:{
    logout(){
      sessionStorage.clear()
      this.$store.dispatch('save_userInfo', {})
      this.$store.dispatch('save_token', undefined)
      this.$router.replace('/login')
    },
    closeLeftMenu(){
      this.isClick = false
    },
    toMyFund(){
      this.$router.push('/personal/all')
      this.isClick = false
    }
  }
};
</script>

<style scoped>
.pd {
  height: 70px;
}
.router-link-exact-active {
  color: #1e50ae !important;
  /* background-color: white; */
}
.header_wrapper {
  width: 100%;
  display: flex;
  height: 70px;
  line-height: 70px;
  justify-content: space-between;
  box-shadow: rgb(136, 136, 136) 0px 0px 10px 0px;
  position: fixed;
  clear: both;
  z-index: 1000;
  background-color: #fff;
}
.header_left {
  display: flex;
}
.logo {
  margin-left: 100px;
  cursor: pointer;
}
.logo img{
  vertical-align: middle;
}
.nav {
  margin-left: 20px;
}
.nav > a {
  padding: 0 30px;
}
.nav a {
  font-size: 14px;
  color: #000;
  display: inline-block;
  transition: all 0.3s linear;
}
.nav a:hover {
  color: #1e50ae;
  background-color: white;
}
@media (max-width: 1200px) {
  .nav > a {
    padding: 0 25px;
  }
  .logo {
    margin-left: 20px;
  }
}
@media (max-width: 1440px) {
  .nav > a {
    padding: 0 18px;
  }
}
.login {
  width: 9vw;
  background: #1e50ae;
  text-align: center;
  font-size: 14px;
  color: #ffffff;
  cursor: pointer;
}
.login img {
  display: inline-block;
  margin-right: 10px;
  vertical-align: baseline;
}
.admin_btn a{
  color: #000;
}
.login_info{
  position: relative;
  width:110px;
  margin-right:50px ;
  background-color: #1e50ae;;
  text-align: center;
  cursor: pointer;
  color: white;
}
.login_info img{
  vertical-align: baseline;
}
.login_info:hover.login_info:before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 0;
    height: 0;
    margin-left: -8px;
    border-width: 0 8px 8px;
    border-style: solid;
    border-color: rgba(0,0,0,0) rgba(0,0,0,0) #fff;
}
.login_info:hover .top_bar{
  padding: 10px 0;
  height: 92px;
}
.login_info .top_bar{
    position: absolute;
    top: 70px;
    left: 50%;
    width: 143px;
    height: 0;
    background: #fff;
    margin-left: -70px;
    -webkit-box-shadow: #aaa 0 1px 5px;
    box-shadow: 0 1px 5px #aaa;
    text-align: center;
    font-size: 14px;
    color: #333;
    line-height: 1;
    overflow: hidden;
    -webkit-transition: height .3s;
    transition: height .3s;
}
.login_info .top_bar div{
  line-height:36px;
  text-align: center;
}
.login_info .top_bar div:hover{
  background-color: #1e50ae;
}
.text-color{
  color:black
}
.login_info .top_bar div:hover .text-color{
  color:white
}
.nav_m,.mobile_placeholder {
  display: none;
}

.header_wrapper_m{
  display: none;
}

.header_wrapper_m .menu .active {
  background: none;
  height: 0.01rem;
}
.header_wrapper_m .menu .active:before {
  top: 0;
  transform: rotate(45deg);
  background: #999;
  height: 0.01rem;
}
.header_wrapper_m .menu .active:after {
  top: 0;
  transform: rotate(-45deg);
  background: #999;
  height: 0.01rem;
}

@media (max-width: 768px) {
  .router-link-exact-active {
    color: #1e50ae !important;
    background: none;
  }
  .pd {
    height: 0.5rem;
  }
  .mobile_placeholder {
    height: 0.5rem;
    display: block;
  }
  .header_wrapper{
    display: none;
  }
  .header_wrapper_m{
    display: block;
  }
  .header_wrapper_m {
    height: 0.5rem;
    line-height: 0.5rem;
    box-shadow: 0 2px 5px 0 rgb(0 0 0 / 10%);
    display: flex;
    position: fixed;
    width: 100%;
    clear: both;
    z-index: 1000;
    background-color: #1e50ae;
    align-items: center;
    /* justify-content: center; */
    /* text-align: center; */
  }
  /* 菜单_叉叉按钮 */
  .menu {
    width: 0.3rem;
    height: 0.3rem;
    flex: 1;
    z-index: 201;
    position: relative;
    /* position: fixed;
    top: 0.1rem;
    left: 0.2rem; */
  }
  .midcross {
    position: absolute;
    top: 0.15rem;
    left: 0.15rem;
    width: 0.27rem;
    height: 0.03rem;
    background-color: #fff;
    border-radius: 0.05rem;
    transform: translateY(-50%);
    transition: all 0.2s ease;
  }
  .midcross:after {
    content: "";
    display: block;
    position: absolute;
    right: 0rem;
    top: 0.1rem;
    width: 0.27rem;
    height: 0.03rem;
    background-color: #fff;
    border-radius: 0.05rem;
    transition: all 0.2s ease;
  }
  .midcross:before {
    content: "";
    display: block;
    position: absolute;
    right: 0rem;
    top: -0.1rem;
    width: 0.27rem;
    height: 0.03rem;
    background-color: #fff;
    border-radius: 0.05rem;
    transition: all 0.2s ease;
  }
  /* 登录按钮 */
  .login_m {
    width: 50px;
    flex: 1;
    /* position: absolute; 
    top: 0rem;
    right: 0rem;*/
  }
  .login_m > span {
    float: right;
    margin-right: 0.15rem;
  }
  .login_m img {
    vertical-align: middle;
  }
  .login_m .login_btn{
    color: white; 
    vertical-align: middle; 
    margin-left: 3px;
    font-size: 14px;
  }
  /* mobie 菜单列表 */
  .menu_list_m {
    position: fixed;
    height: 100%;
    z-index: 200;
    width: 2.03rem;
    background-color: #fff;
    box-shadow: 0 0.15rem 0.16rem rgb(31 31 31 / 50%);
    top: 0;
    left: -100%;
    box-sizing: border-box;
    padding-top: 0.5rem;
    transition: all 0.5s linear;
  }
  .header_wrapper_m .menu_list_m.active {
    left: -1px;
  }
  .nav_m {
    display: block;
    border-top: 1px solid #f2f2f2;
    padding: 0 16px;
    text-align: center;
  }
  .nav_m li {
    display: block;
    height: 0.4rem;
    line-height: 0.4rem;
    /* padding-left: 0.3rem; */
    border-bottom: 1px solid #f2f2f2;
  }
  .nav_m a {
    color: #666666;
    font-size: 0.14rem;
  }
  /* .nav_m a:active{
    color: #fff;
  } */
}

</style>
