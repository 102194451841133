<template>
  <div>
    <div class="swiper-container pc">
        <div class="swiper-wrapper">
            <!-- <div class="swiper-slide">
              <img src="https://fbrj-hz-01.oss-cn-hangzhou.aliyuncs.com/assets/home-bg1.png">
            </div> -->
            <div class="swiper-slide">
              <img src="https://fbrj-hz-01.oss-cn-hangzhou.aliyuncs.com/assets/home-bg2.png">
            </div>
        </div>
        <!-- <div class="swiper-pagination p"></div> -->
        <div class="swiper-button-prev"></div>
        <div class="swiper-button-next"></div>
        <!-- <div style="position: absolute;bottom: 6vh;right: 200px;color: white;font-weight: bold;z-index: 999;font-size: 20px;">投资有风险,入市需谨慎</div> -->
    </div>
    <div class="swiper-container mobile">
      <div class="swiper-wrapper">
          <!-- <div class="swiper-slide">
            <img height="220px" src="https://fbrj-hz-01.oss-cn-hangzhou.aliyuncs.com/assets/home-bg6.jpg">
          </div> -->
          <div class="swiper-slide">
            <img height="220px" src="https://fbrj-hz-01.oss-cn-hangzhou.aliyuncs.com/assets/home-bg7.jpg">
          </div>
      </div>
      <div class="swiper-pagination p"></div>
      <div class="swiper-button-prev"></div>
      <div class="swiper-button-next"></div>
    </div>    
    <div class="section_wrapper">
      <div class="section1 clearboth">
        <div class="section1_left">
          <h3>ABOUT US
          <span>关于我们</span>
            <a href="/aboutus" class="pc">更多</a>
          </h3>
          <p>杭州富邦瑞锦私募基金管理有限公司（简称富邦瑞锦）成立于2022年6月，由多位专业金融业人士联合创立，专注于A股二级市场投资，以“价值、专业、规范，对客户负责”为价值观，凭借领先的金融科技技术、扎实的投资研究功底、稳健的基金运作模式，为专业人士和机构提供资产管理服务。</p>
          <ul class="clearboth index-ul">
            <li>
              <b><em>2</em><em>0</em><em>2</em><em>2</em><sup>年</sup></b>
              <p>富邦瑞锦创立</p>
            </li>
            <li>
              <b><em>N</em><sup>+</sup></b>
              <p>科技赋能</p>
            </li>
            <li>
              <b><em>1</em><em>0</em><sup>年+</sup></b>
              <p>核心团队<br>10年+行业经验</p>
            </li>
            <!-- <li>
              <b><em>5</em><em>0</em><sup>%+</sup></b>
              <p>年化收益率</p>
            </li> -->
          </ul>
          <router-link to="/aboutus" class="section1_left_a mobile">更多</router-link>
          <!-- <a href="/aboutus" class="section1_left_a mobile">更多</a> -->

        </div>
        <div class="section1_right"> 
          <img src="https://fbrj-hz-01.oss-cn-hangzhou.aliyuncs.com//assets/home-bg3.jpg" class="pc"/>
        </div>        
      </div>
      <div class="section2">
        <div style="position:relative;height:150px">
          <h3>基金产品</h3>
          <p>立足全球市场，对中国概念的价值股进行投资</p>
          <router-link to="/fund">更多</router-link>
        </div>
      </div>
      <div class="section2 join">
        <div style="position:relative;height:150px">
          <h3>诚聘英才</h3>
          <p>招聘 — 有所作为的机会</p>
          <router-link to="/joinus">更多</router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Swiper from 'swiper'; // 注意引入的是Swiper
import 'swiper/css/swiper.min.css' // 注意这里的引入
export default {
  name: 'Home',
  data(){
    return{
    }
  },
  mounted() {
    new Swiper('.swiper-container', {
      loop: true,
      autoplay: {
        delay: 6000,
        disableOnInteraction: false,
      }, 
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
      navigation: {
        nextEl: '.swiper-button-next',//自动隐藏
        prevEl: '.swiper-button-prev',//自动隐藏
        // hiddenClass: 'btn-hidden',//某些情况下需要隐藏前进后退按钮时，可以设定一个自定义的类名。
      }
    });
	},

}
</script>
 
<style scoped>
.pc{
  display: block;
}
.mobile{
  display: none;
}
.swiper-container {
  width: 100%;
  height: 60vh;
}
.swiper-slide {
  width: 100%;
  height: 100%;
}
.swiper-pagination{
  display: none;
}

.swiper-button-prev,.swiper-button-next{
  color: #fff;
}

.section_wrapper .section1 {
    min-width: 400px;
    margin: 0 auto;
    width: 80%;
    padding: 80px 0 100px;
    clear: both;
}

.section_wrapper .section1 .section1_left {
    width: 48%;
    box-sizing: border-box;
    padding-left: 4%;
    position: relative;
    float: left;
}
.section_wrapper .section1 .section1_left:before {
    content: '';
    position: absolute;
    display: block;
    width: 14px;
    height: 13px;
    background: url(https://fbrj-hz-01.oss-cn-hangzhou.aliyuncs.com/assets/colon.png) no-repeat;
    left: 0;
}
.section1 .section1_left h3{
  font-size: 30px;
  color: #333333;
  position: relative;
  font-weight: normal;
}
.section1 .section1_left h3 span {
    display: block;
    font-size: 16px;
    color: #666666;
    margin-bottom: 50px;
    position: relative;
    margin-top: 15px;
}
.section1 .section1_left h3 span:before {
    display: block;
    content: '';
    position: absolute;
    width: 20px;
    height: 1px;
    background-color: #999;
    top: 50%;
    left: 13%;
}
.section1 .section1_left h3 a {
    display: block;
    position: absolute;
    right: 0;
    font-size: 14px;
    top: 0;
    color: #666666;
    transition: all 0.3s ease;
}
.section1 .section1_left h3 a:before {
    display: block;
    content: '';
    position: absolute;
    width: 20px;
    height: 1px;
    background-color: #999;
    top: 43%;
    left: -30px;
}
.section1 .section1_left p {
    font-size: 16px;
    color: #666666;
    line-height: 28px;
    margin-bottom: 80px;
}
.section1 .section1_left .index-ul {
    margin-top: 60px;
}
.clearboth {
    clear: both;
}
ul, li {
    list-style: none;
    margin: 0;
    padding: 0;
}
.section1 .section1_left .index-ul li {
    margin-right: 4.4%;
    text-align: center;
    float: left;
}
.section1 .section1_left .index-ul li b {
    font-size: 48px;
    color: #666666;
    display: block;
    line-height: 1;
}
.section1 .section1_left .index-ul li b em {
    display: inline-block;
    position: relative;

}
.section1 .section1_left .index-ul li b sup {
    font-size: 14px;
    color: #999999;
    vertical-align: text-top;
    top: 0;
}
em, i {
    font-style: normal;
}
.section1 .section1_right {
    width: 42%;
    float: right;
}
.mobile {
    display: none!important;
}
.section2 {
    background: url(https://fbrj-hz-01.oss-cn-hangzhou.aliyuncs.com/assets/home-bg4.png) no-repeat center;
    background-size: cover;
    padding: 150px 0;
    text-align: center;
    color: white;
}
.join{
  background: url(https://fbrj-hz-01.oss-cn-hangzhou.aliyuncs.com/assets/home-bg5.png) no-repeat center;
  background-size: cover;
  padding: 150px 0;
}
.section2 h3 {
    font-size: 20px;
    margin-bottom: 35px;
    font-weight: bold;
    color: white;
}
.section2 p {
    font-size: 16px;
}
.section2 a {
    display: block;
    margin: 35px auto 0;
    width: 100px;
    height: 35px;
    border-radius: 20px;
    background-color: #1E50AE;
    line-height: 35px;
    color: #fff;
    font-size: 14px;
    transition: all 0.3s linear;
}
.section2 a:hover {
    background-color: #1E50AE;
    width: 120px;
}
@media (max-width: 1220px){
  .section1 .section1_right {
    display: none;
  }
  .section_wrapper .section1 .section1_left {
    width: 100%;
  }
}

@media (max-width: 768px){
  .pc {
    display: none!important;
  }
  .mobile{
    display: block !important;
  }
  .swiper-container {
    height: auto;
  }
  .swiper-wrapper{
    height: 33vh;
  }
  .swiper-button-next,.swiper-button-prev{
    display: none;
  }
  .swiper-pagination{
    display: block;
  }
  .swiper-slide img{
    width: 100%;
    display: block;
  }
 
  .section_wrapper .section1 {
    min-width: 3.45rem;
    padding: 0.44rem 0.15rem;
    /* width: 100%; */
  }
  .section_wrapper .section1 .section1_left {
    width: 100%;
    padding-left: 0;
    text-align: center;
  }
  
  .section_wrapper .section1 .section1_left:before {
    display: none
  }
  .section1 .section1_left h3{
    font-size: 0.2rem;
  }
  .section1 .section1_left h3 span {
    font-size:0.12rem ;
  }

  .section1 .section1_left h3 span:before {
    width: 0.1rem;
    left: 50%;
    margin-left: -0.05rem;
    top: 0.25rem;
  }
  .section1 .section1_left p {
    font-size: 0.12rem;
    line-height: 0.23rem;
    margin: 0;
  }
  .section1 .section1_left .index-ul li {
    margin-right: 0;
    width: 25%;
  }
  .section1 .section1_left .index-ul li b {
    font-size: 0.24rem;
  }
  .section1 .section1_left .index-ul li b sup{
    font-size: 0.12rem;
  }
  .section1 .section1_left .section1_left_a {
    width: 1.18rem;
    height: 0.35rem;
    line-height: 0.35rem;
    border-radius: 0.20rem;
    display: block;
    margin: 0.33rem auto;
    background-color: #023a8e;
    color: #fff;
    font-size: 0.12rem;
    text-align: center;
  }
  .section2 {
    background: url(https://fbrj-hz-01.oss-cn-hangzhou.aliyuncs.com/assets/home-bg6-m.jpg) no-repeat center;
    background-size: cover;
    padding: 0.6rem 0;
    text-align: center;
    color: white !important;
  }
  .join{
    background: url(https://fbrj-hz-01.oss-cn-hangzhou.aliyuncs.com/assets/home-bg7-m.jpg) no-repeat center;
    background-size: cover;
    padding: 0.6rem 0;
  }
}
</style>
