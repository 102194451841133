import Vue from 'vue'
import App from './App.vue'

import store from './store/index'
import VueRouter from 'vue-router'
import router from './router/index'
Vue.use(VueRouter)

import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';
Vue.use(Antd)

// import infiniteScroll from 'vue-infinite-scroll'
// Vue.use(infiniteScroll)

import { message } from 'ant-design-vue';
Vue.prototype.$message = message
message.config({
  top: `100px`,
  maxCount: 3,
});

Vue.config.productionTip = false
new Vue({
  render: h => h(App),
  router,
  store
}).$mount('#app')
