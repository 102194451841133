import service from '@/utils/request'

export const login = (data) => {
  return service({
    url: '/login',
    method: 'post',
    data: data
  })
}

export const getUserInfo = () => {
  return service({
    url: '/user/info',
    method: 'get',
  })
}

export const changePwd = (data) => {
  return service({
    url: '/user/edit_pwd',
    method: 'post',
    data: data
  })
}