<template>
  <div>
    <div class="bg">

    </div>
    <div class="about-main">
      <div class="about_section1 w1200">
        <div class="about_title">
          <h2>公司概述</h2>
          <p style="text-align: justify;text-indent:2em">
            杭州富邦瑞锦私募基金管理有限公司（基金管理人登记编码：P1074512）成立于2022年6月1日，由多位长期从事证券投资行业的专业人士联合创立，专注于A股二级市场投资，并不断探索不同风险收益特征的多策略投资方向。公司秉持“价值、专业、规范，对客户负责”的价值观，围绕建立一流的证券类私募管理人为发展目标，凭借领先的金融科技技术、扎实的投资研究功底、稳健的基金运作模式，长期为专业人士和机构提供资产管理服务。富邦瑞锦现已形成了包括投研、系统建设、市场销售、合规风控、人力资源和财务支持在内的的前中后台保障运营体系。        
          </p>
        </div>
      </div>
      <div class="about-section2" v-show="isMb">
        <div class="w1200">
          <div class="about_title">
            <h2>竞争力</h2>
          </div>
          <div class="swiper">
            <div class="join-switch mobile">
              <span class="join-switch-prev swiper-button-prev swiper-button" ></span>
              <span class="join-switch-next swiper-button-next swiper-button" ></span>
            </div>
            <div class="about-section2-swiper">
              <div class="swiper-wrapper clearboth">
                <div class="swiper-slide mb40">
                  <img width="120" src="https://fbrj-hz-01.oss-cn-hangzhou.aliyuncs.com/assets/list1.png" alt="">
                  <h3>本土化</h3>
                  <p>根植本土 洞悉中国</p>
                </div>
                <div class="swiper-slide mb40">
                  <img width="120" src="https://fbrj-hz-01.oss-cn-hangzhou.aliyuncs.com/assets/list2.png" alt="">
                  <h3>国际化</h3>
                  <p>登高望远 放眼寰球</p>
                </div>
                <div class="swiper-slide mb40">
                  <img width="120" src="https://fbrj-hz-01.oss-cn-hangzhou.aliyuncs.com/assets/list3.png" alt="">
                  <h3>科技化</h3>
                  <p>以人为本 科技赋能</p>
                </div>                
                <div class="swiper-slide">
                  <img width="120" src="https://fbrj-hz-01.oss-cn-hangzhou.aliyuncs.com/assets/list4.png" alt="">
                  <h3>团队化</h3>
                  <p>英才荟萃 凝聚力量</p>
                </div>
                <div class="swiper-slide">
                  <img width="120" src="https://fbrj-hz-01.oss-cn-hangzhou.aliyuncs.com/assets/list6.png" alt="">
                  <h3>合规化</h3>
                  <p>严守规控 保障安全</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="about-section2" v-show="!isMb">
        <div class="w1200">
          <div class="about_title">
            <h2>竞争力</h2>
          </div>
</div>
          <div class="jz-wrapper">
            <div class="mb40">
              <img width="120" src="https://fbrj-hz-01.oss-cn-hangzhou.aliyuncs.com/assets/list1.png" alt="">
              <h3>本土化</h3>
              <p>根植本土 洞悉中国</p>
            </div>
            <div class=" mb40">
              <img width="120" src="https://fbrj-hz-01.oss-cn-hangzhou.aliyuncs.com/assets/list2.png" alt="">
              <h3>国际化</h3>
              <p>登高望远 放眼寰球</p>
            </div>
            <div class="mb40">
              <img width="120" src="https://fbrj-hz-01.oss-cn-hangzhou.aliyuncs.com/assets/list3.png" alt="">
              <h3>科技化</h3>
              <p>以人为本 科技赋能</p>
            </div>                
            <div class="">
              <img width="120" src="https://fbrj-hz-01.oss-cn-hangzhou.aliyuncs.com/assets/list4.png" alt="">
              <h3>团队化</h3>
              <p>英才荟萃 凝聚力量</p>
            </div>
            <div class="">
              <img width="120" src="https://fbrj-hz-01.oss-cn-hangzhou.aliyuncs.com/assets/list6.png" alt="">
              <h3>合规化</h3>
              <p>严守规控 保障安全</p>
            </div>
        
      </div>
      </div>      
      <div class="about_section3 w1200">
        <div class="about_title">
          <h2>创始人寄语</h2>
          <p>我们的成功依赖于一起工作的团队。为此我们提供了多样化的学习平台和成长机会。<br>
            我们欢迎积极思考、敢于提问、热爱挑战的人们，与团队中同样有才华有经验的同事一起，面对复杂挑战。</p>
        </div>
      </div>
      <div class="about_section4">
        <div class="w1200">
          <div class="about_title">
            <h2>核心团队</h2>
          </div>
          <div class="about_section4_main">
              <div class="container">
                  <div style="width: 50%;background: #fff;text-align: center;padding-top: 15px;">
                      <img src="https://fbrj-hz-01.oss-cn-hangzhou.aliyuncs.com/assets/icon1.svg" width="40px">
                      <p>股东会</p>
                  </div>
                  <div style="flex-grow: 1;background: #fff;margin-left:10px;text-align: center;padding-top: 15px;">
                      <img src="https://fbrj-hz-01.oss-cn-hangzhou.aliyuncs.com/assets/icon2.svg" height="43px">
                      <p>投资决策委员会</p>
                  </div>
              </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Swiper from 'swiper'; // 注意引入的是Swiper
import 'swiper/css/swiper.min.css' // 注意这里的引入
export default {
  name: 'Home',
  data(){
    return{
      swiper:null
    }
  },
  mounted() {
    if(document.documentElement.clientWidth <= 780){
        this.swiper = new Swiper('.about-section2-swiper', {
          slidesPerView : 2,
          navigation: {
            nextEl: '.swiper-button-next',//自动隐藏
            prevEl: '.swiper-button-prev',//自动隐藏
            hiddenClass: 'btn-hidden',//某些情况下需要隐藏前进后退按钮时，可以设定一个自定义的类名。
          }
        })
    } 
	},
  computed:{
    isMb(){
      return document.documentElement.clientWidth<768
    },                
  },    
  methods:{
    slidePrev(){
      this.swiper.slidePrev()
    },
    slideNext(){
      this.swiper.slideNext()

    }
  }
 
}
</script>
 
<style scoped>
.pc{
  display: block;
}

.bg {
    background-image: url(https://fbrj-hz-01.oss-cn-hangzhou.aliyuncs.com/assets/about-bg1.png);
    background-size: cover;
    background-position: 50% 50%;
    padding-bottom: 23.510971%;
}
.banner {
    position: relative;
    text-align: center;
}
.banner img {
    display: block;
    width: 100%;
}
.mobile {
    display: none!important;
}
.banner .banner_text {
  position: absolute;
  top: 50%;
  width: 100%;
  color: #fff;
  font-size: 48px;
  margin-top: -36px;
}
 .about_section1 {
    padding: 100px 0;
}
.about_title{
  text-align: center;
}
.about_title h2 {
    font-size: 32px;
    color: #0c0e82;
    margin-bottom: 30px;
    font-weight: normal;
}
 .about_title P {
    font-size: 14px;
    color: #303a46;
    line-height: 28px;
}
.about-section2 {
    background: url(https://fbrj-hz-01.oss-cn-hangzhou.aliyuncs.com/assets/about-bg2.png) no-repeat center;
    background-size: cover;
    padding: 100px 0;
}
.w1200 {
    width: 1200px;
    margin: 0 auto;
}

.mb40 {
    margin-bottom: 40px;
}
.about-main .about-section2 .about-section2-swiper .swiper-slide:before {
    display: block;
    position: absolute;
    right: 0;
    content: '';
    border-left: 1px solid #e1e8f0;
    height: 80%;
    top: 10%;
}
.about-main .about-section2 .about-section2-swiper .swiper-wrapper {
  clear: both;
    display: block;
}
.about-main .about-section2 .about-section2-swiper .swiper-slide  {
    float: left;
    width: 33.3333%;
    text-align: center;
    position: relative;
}
.about-main .join-switch span:hover {
    background-position: 11px -29px;
}

@media (min-width: 1024px){
.about-main .about-section2 .about-section2-swiper .swiper-slide img {
    transition: all 0.3s linear;
}
}
@media (min-width: 1024px){
  .about-main .about-section2 .about-section2-swiper .swiper-slide:hover img {
    transform: scale(1.2);
}
}
.about-main .about-section2 .about-section2-swiper .swiper-slide h3 {
    font-size: 18px;
    /* margin-top: 20px; */
    color: #c90a0a;
}
.about-main .about-section2 .about-section2-swiper .swiper-slide h3:after {
    display: block;
    content: '';
    margin: 15px auto;
    width: 20px;
    height: 2px;
    background-color: #666;
}
.about-main .about-section2 .about-section2-swiper .swiper-slide p {
    font-size: 14px;
    color: #565a66;
}
.about-main .about_section3 {
    padding: 90px 0;
}
.about-main .about_section4 {
    background: url(https://fbrj-hz-01.oss-cn-hangzhou.aliyuncs.com/assets/about-bg14.png) no-repeat top;
    background-size: cover;
    padding: 110px 0 120px;
}
.container {
    display: flex; 
    height: 100px; 
    width: 600px;
}
/* 箭头 */
.about-main .join-switch {
    width: 100%;
    position: absolute;
    top: 50%;
    margin-top: -17px;
}
.about-main .join-switch span {
    display: block;
    width: 35px;
    height: 35px;
    border-radius: 100%;
    z-index: 999;
    background: rgba(0, 0, 0, 0.2) url(https://fbrj-hz-01.oss-cn-hangzhou.aliyuncs.com/assets/slign.png) no-repeat 11px 7px;
    position: absolute;
    cursor: pointer;
}
.about-main .join-switch span.join-switch-next {
    transform: rotate(180deg);
}
.swiper-button-next:after, .swiper-container-rtl .swiper-button-prev:after {
    content: ' ';
}
.swiper-button-prev:after, .swiper-container-rtl .swiper-button-next:after {
    content: ' ';
}

.about-main .about_section4 .about_section4_main {
    display: flex;
    justify-content: center;
}
/* .about_section4_main img{
  width: 60px;
} */

@media (max-width: 768px){
  .bg{
    background-image: url(https://fbrj-hz-01.oss-cn-hangzhou.aliyuncs.com/assets/about-bg1-m.jpg);
    padding-bottom: 33vh;
  }
  .about-section2 {
    background: url(https://fbrj-hz-01.oss-cn-hangzhou.aliyuncs.com/assets/about-bg2-m.png) no-repeat;
    background-size: cover;
  }
  .about-main .about_section4 {
    background: url(https://fbrj-hz-01.oss-cn-hangzhou.aliyuncs.com/assets/about-bg14-m.png) no-repeat;
    background-size: cover;
   }
  .mobile {
    display: block!important;
  }
  .pc {
    display: none!important;
  }
.banner .banner_text h3:first-child {
    margin-bottom: 0.1rem;
}

.banner .banner_text h3 {
    font-size: 0.18rem;
}
 
.about-main .about_section1 {
    padding: 0.45rem 0;
}
.w1200 {
    width: auto;
    padding: 0 0.15rem;
}
.about-main .about_title {
    padding: 0 0.15rem;
}
.about-main .about_title h2 {
    font-size: 0.2rem;
    margin-bottom: 0.225rem;
}

.about-main .about_title p {
    font-size: 12px;
    line-height: 0.23rem;
}
.about-main .about-section2 {
    overflow: hidden;
    padding: 0.55rem 0;
}
.about-main .about-section2 .swiper {
  position: relative;
}

.about-main .join-switch span.join-switch-prev {
    left: -12px;
}
.about-main .join-switch span.join-switch-next {
    right: -12px;
}
.about-main .about-section2 .about-section2-swiper .swiper-wrapper {
    display: flex;
}
.about-main .about-section2 .about-section2-swiper .swiper-slide img {
    width: 0.65rem;
}
.about-main .about-section2 .about-section2-swiper .swiper-slide h3{
    font-size: 0.13rem;
}
.about-main .about-section2 .about-section2-swiper .swiper-slide p{
    font-size: 12px;
    padding: 0 0.175rem;
}
.about-main .about_section3 {
    padding: 0.5rem 0;
}
.about-main .about_section4 {
    padding: 0.5rem 0 0.6rem;
}

.container {
  width: 90vw;
}
}
.jz-wrapper div{
  text-align: center;
}
.jz-wrapper{
  display: flex;
  justify-content: space-around;
}
</style>
