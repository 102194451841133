<template>
  <div>
    <!-- <div class="bg">

    </div> -->
    <div class="main_wrapper">
      <div class="main2">
        <h1>开放职位</h1><br>
        <div class="job_list_wrapper">
          <div class="job_item">
            <router-link to="/jobdetail?id=0">
              <span></span>
              <h3>行业研究员</h3>
              <em>2023-03-01</em>
            </router-link>
          </div>
          <div class="job_item">
            <router-link to="/jobdetail?id=1">
              <span></span>
              <h3>投资助理（资讯方向)</h3>
              <em>2023-03-01</em>
            </router-link>
          </div> 
        </div>
      </div>

    </div>
  </div>
</template>

<script>
  export default {
    data(){
      return{
        isfulltime:true
      }
    }
  }
</script>

<style scoped>
.bg {
    background-image: url(https://fbrj-hz-01.oss-cn-hangzhou.aliyuncs.com/assets/join-bg2.png);
    background-size: cover;
    background-position: 50% 50%;
    padding-bottom: 23%;
}
.main_wrapper{
  height: calc(100vh - 207px);
  background: url(https://fbrj-hz-01.oss-cn-hangzhou.aliyuncs.com/assets/join-bg2.png) no-repeat center;
  background-size: cover;
}
.bg_wrapper {
    position: relative;
    text-align: center;
}
.mobile {
    display: none!important;
}
img {
    display: block;
    width: 100%;
}
.bg_text {
  position: absolute;
  top: 50%;
  width: 100%;
  color: #fff;
  font-size: 48px;
  margin-top: -36px;
}
.main1{
  /* background: url(../assets/join2.jpg) no-repeat center; */
  background-size: cover;
  padding: 110px 0;
  text-align: center;
}
.main1_txt_wrapper{
  width: 950px; 
  text-align: justify; 
  line-height: 24px;
  margin: 0 auto;
  font-size: 16px;
}
.main1_txt_wrapper p{
  margin-bottom: 20px
}
.main2 {
  padding: 90px 0 100px;

}
.main2 h1 {
  text-align: center;
  font-size: 28px;
  color: #023a8e;
}
.main2 p{
  width: 950px;
  font-size: 16px; 
  color: #666666; 
  display: block; 
  line-height: 22px;
  margin: 30px auto 55px;
}
.nav_btn {
    font-size: 0;
    text-align: center;
    margin-bottom: 30px;
}
.nav_btn span.active {
    background: #023a8e;
    border-color: #023a8e;
    color: #fff;
}
.nav_btn span {
    display: inline-block;
    /* vertical-align: middle; */
    width: 120px;
    height: 30px;
    line-height: 30px;
    box-sizing: border-box;
    font-size: 14px;
    color: #333333;
    margin: 0 10px;
    cursor: pointer;
    border: 1px solid #cccccc;
}
.job_list_wrapper{
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.job_item{
  width: 285px;
  height: 345px;
  background-color: rgba(239, 246, 255, 0.6);
  box-sizing: border-box;
  border: 1px solid #a3b3d1;
  text-align: center;
  padding: 40px 45px 0px;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  transition: all 0.3s ease;
  margin-right: 20px;
  margin-bottom: 10px;
  /* cursor: pointer; */
}
.job_item:hover {
    background: #0f3a8e;
    border-color: #0f3a8e;
}
.job_item:hover span{
    background: url(https://fbrj-hz-01.oss-cn-hangzhou.aliyuncs.com/assets/join-bg3_.png) no-repeat -1px -1px;
}
.job_item:hover span {
    border-color: #fff;
    /* background-position: 22px 19px; */
}
.job_item:hover h3 {
    color: #fff;
}
.job_item:hover h3:after {
    background-color: #fff;
}
.job_item:hover em {
    color: #fff;
}
.job_item span {
    display: block;
    margin: 0 auto;
    /* background: url(https://fbrj-hz-01.oss-cn-hangzhou.aliyuncs.com/assets/join-bg3.png) no-repeat 22px -92px; */
    background: url(https://fbrj-hz-01.oss-cn-hangzhou.aliyuncs.com/assets/join-bg3.png) no-repeat -1px -1px;
    width: 100px;
    height: 100px;
    border-radius: 100%;
    -webkit-border-radius: 100%;
    border: 1px solid #666666;
}
.job_item h3 {
    font-size: 18px;
    color: #333333;
    margin-top: 40px;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    transition: all 0.3s ease;
}
.job_item h3:after {
    display: block;
    content: '';
    margin: 30px auto 30px;
    width: 30px;
    height: 1px;
    background-color: #ccc;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    transition: all 0.3s ease;
}
.job_item em {
    font-size: 14px;
    color: #5a5a5a;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    transition: all 0.3s ease;
    font-style: normal;
}
.about_title{
  text-align: center;
}
.about_title h2 {
    font-size: 32px;
    color: #0c0e82;
    margin-bottom: 30px;
    font-weight: normal;
}
 .about_title P {
    font-size: 16px;
    color: #303a46;
    line-height: 28px;
}

@media (max-width: 768px){
 .pc {
    display: none!important;
  } 
  .mobile {
    display: block!important;
  }
  .bg {
    padding-bottom: 25vh;
    background-image: url(https://fbrj-hz-01.oss-cn-hangzhou.aliyuncs.com/assets/join-bg1.png);
  }
  .main2 {
    background: url(https://fbrj-hz-01.oss-cn-hangzhou.aliyuncs.com/assets/join-bg2.png) no-repeat center;
    background-size: cover;
  }
  .main_wrapper{
    height: calc(100vh - 0.5rem - 62.6px);
  }
  /* .bg_text {
    width: 100%;
    margin-left: 0;
    left: 0;
    text-align: center;
    margin-top: -24px;
  } */
  .bg_text span{
    font-size: 0.18rem;
  }
  .main1{
    padding: 0.45rem 0;
  }
  .main1_txt_wrapper{
    width: 100%;
    padding: 0 0.15rem;
    box-sizing: border-box;
  }
  .main1_txt_wrapper p {
    font-size: 0.12rem;
    line-height: 0.23rem;
  }
  .main2{
    padding: 0.55rem 0;
  }
  .main2 h1 {
    font-size: 0.18rem;
  }
  .main2 p{
    width: 90%;
    font-size: 0.14rem; 
  }
  .job_item{
    width: 34vw;
    /* height: 30vw; */
    padding: 0.2rem 0.15rem;
    height: auto;
  }
  .job_item span {
    width: 0.58rem;
    height: 0.58rem;
    background: url(https://fbrj-hz-01.oss-cn-hangzhou.aliyuncs.com/assets/photo2.png) no-repeat center;
    background-size: 60%;
  }
  .job_item h3 {
    font-size: 0.13rem;
    margin-top: 0.15rem;
  }
  .job_item h3:after {
    display: none;
  }
  .job_item em {
    font-size: 0.12rem;
  }
  .about_title {
    padding: 0 0.15rem;
  }
  .about_title h2 {
    font-size: 0.2rem;
    margin-bottom: 0.225rem;
  }
  .about_title p {
    font-size: 12px;
    line-height: 0.23rem;
  }
}

</style>