var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"bg"}),_c('div',{staticClass:"about-main"},[_vm._m(0),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isMb),expression:"isMb"}],staticClass:"about-section2"},[_vm._m(1)]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isMb),expression:"!isMb"}],staticClass:"about-section2"},[_vm._m(2),_vm._m(3)]),_vm._m(4),_vm._m(5)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"about_section1 w1200"},[_c('div',{staticClass:"about_title"},[_c('h2',[_vm._v("公司概述")]),_c('p',{staticStyle:{"text-align":"justify","text-indent":"2em"}},[_vm._v(" 杭州富邦瑞锦私募基金管理有限公司（基金管理人登记编码：P1074512）成立于2022年6月1日，由多位长期从事证券投资行业的专业人士联合创立，专注于A股二级市场投资，并不断探索不同风险收益特征的多策略投资方向。公司秉持“价值、专业、规范，对客户负责”的价值观，围绕建立一流的证券类私募管理人为发展目标，凭借领先的金融科技技术、扎实的投资研究功底、稳健的基金运作模式，长期为专业人士和机构提供资产管理服务。富邦瑞锦现已形成了包括投研、系统建设、市场销售、合规风控、人力资源和财务支持在内的的前中后台保障运营体系。         ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"w1200"},[_c('div',{staticClass:"about_title"},[_c('h2',[_vm._v("竞争力")])]),_c('div',{staticClass:"swiper"},[_c('div',{staticClass:"join-switch mobile"},[_c('span',{staticClass:"join-switch-prev swiper-button-prev swiper-button"}),_c('span',{staticClass:"join-switch-next swiper-button-next swiper-button"})]),_c('div',{staticClass:"about-section2-swiper"},[_c('div',{staticClass:"swiper-wrapper clearboth"},[_c('div',{staticClass:"swiper-slide mb40"},[_c('img',{attrs:{"width":"120","src":"https://fbrj-hz-01.oss-cn-hangzhou.aliyuncs.com/assets/list1.png","alt":""}}),_c('h3',[_vm._v("本土化")]),_c('p',[_vm._v("根植本土 洞悉中国")])]),_c('div',{staticClass:"swiper-slide mb40"},[_c('img',{attrs:{"width":"120","src":"https://fbrj-hz-01.oss-cn-hangzhou.aliyuncs.com/assets/list2.png","alt":""}}),_c('h3',[_vm._v("国际化")]),_c('p',[_vm._v("登高望远 放眼寰球")])]),_c('div',{staticClass:"swiper-slide mb40"},[_c('img',{attrs:{"width":"120","src":"https://fbrj-hz-01.oss-cn-hangzhou.aliyuncs.com/assets/list3.png","alt":""}}),_c('h3',[_vm._v("科技化")]),_c('p',[_vm._v("以人为本 科技赋能")])]),_c('div',{staticClass:"swiper-slide"},[_c('img',{attrs:{"width":"120","src":"https://fbrj-hz-01.oss-cn-hangzhou.aliyuncs.com/assets/list4.png","alt":""}}),_c('h3',[_vm._v("团队化")]),_c('p',[_vm._v("英才荟萃 凝聚力量")])]),_c('div',{staticClass:"swiper-slide"},[_c('img',{attrs:{"width":"120","src":"https://fbrj-hz-01.oss-cn-hangzhou.aliyuncs.com/assets/list6.png","alt":""}}),_c('h3',[_vm._v("合规化")]),_c('p',[_vm._v("严守规控 保障安全")])])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"w1200"},[_c('div',{staticClass:"about_title"},[_c('h2',[_vm._v("竞争力")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"jz-wrapper"},[_c('div',{staticClass:"mb40"},[_c('img',{attrs:{"width":"120","src":"https://fbrj-hz-01.oss-cn-hangzhou.aliyuncs.com/assets/list1.png","alt":""}}),_c('h3',[_vm._v("本土化")]),_c('p',[_vm._v("根植本土 洞悉中国")])]),_c('div',{staticClass:"mb40"},[_c('img',{attrs:{"width":"120","src":"https://fbrj-hz-01.oss-cn-hangzhou.aliyuncs.com/assets/list2.png","alt":""}}),_c('h3',[_vm._v("国际化")]),_c('p',[_vm._v("登高望远 放眼寰球")])]),_c('div',{staticClass:"mb40"},[_c('img',{attrs:{"width":"120","src":"https://fbrj-hz-01.oss-cn-hangzhou.aliyuncs.com/assets/list3.png","alt":""}}),_c('h3',[_vm._v("科技化")]),_c('p',[_vm._v("以人为本 科技赋能")])]),_c('div',{},[_c('img',{attrs:{"width":"120","src":"https://fbrj-hz-01.oss-cn-hangzhou.aliyuncs.com/assets/list4.png","alt":""}}),_c('h3',[_vm._v("团队化")]),_c('p',[_vm._v("英才荟萃 凝聚力量")])]),_c('div',{},[_c('img',{attrs:{"width":"120","src":"https://fbrj-hz-01.oss-cn-hangzhou.aliyuncs.com/assets/list6.png","alt":""}}),_c('h3',[_vm._v("合规化")]),_c('p',[_vm._v("严守规控 保障安全")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"about_section3 w1200"},[_c('div',{staticClass:"about_title"},[_c('h2',[_vm._v("创始人寄语")]),_c('p',[_vm._v("我们的成功依赖于一起工作的团队。为此我们提供了多样化的学习平台和成长机会。"),_c('br'),_vm._v(" 我们欢迎积极思考、敢于提问、热爱挑战的人们，与团队中同样有才华有经验的同事一起，面对复杂挑战。")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"about_section4"},[_c('div',{staticClass:"w1200"},[_c('div',{staticClass:"about_title"},[_c('h2',[_vm._v("核心团队")])]),_c('div',{staticClass:"about_section4_main"},[_c('div',{staticClass:"container"},[_c('div',{staticStyle:{"width":"50%","background":"#fff","text-align":"center","padding-top":"15px"}},[_c('img',{attrs:{"src":"https://fbrj-hz-01.oss-cn-hangzhou.aliyuncs.com/assets/icon1.svg","width":"40px"}}),_c('p',[_vm._v("股东会")])]),_c('div',{staticStyle:{"flex-grow":"1","background":"#fff","margin-left":"10px","text-align":"center","padding-top":"15px"}},[_c('img',{attrs:{"src":"https://fbrj-hz-01.oss-cn-hangzhou.aliyuncs.com/assets/icon2.svg","height":"43px"}}),_c('p',[_vm._v("投资决策委员会")])])])])])])
}]

export { render, staticRenderFns }