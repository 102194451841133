import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    userInfo: {
      pwd:false
    },
    token: sessionStorage.getItem('token'),
  },
  getters: {
    info(state) {
      return state;
    }
  },
  mutations: {
    SAVE_USERINFO(state, payload) {
      state.userInfo = payload
    },
    SAVE_TOKEN(state, payload) {
      state.token = payload
    },
  },
  actions: {
    save_userInfo({ commit }, payload) {
      commit('SAVE_USERINFO', payload)
    },
    save_token({ commit }, payload) {
      commit('SAVE_TOKEN', payload)
    },
  }
})