<template>
  <div id="app">
    <!-- <Home/> -->
    <HeaderBar ref="header" v-show="$route.meta.haveheader === undefined"/>
    <div @click.passive="cloeLeftMenu">
      <router-view></router-view>
    </div>
    <FooterBar v-show="$route.meta.havefooter === undefined" />

    <div class="mask" v-if="isShow">
    </div>
    <div class="modal" v-if="isShow">
      <div style="text-align:center;margin-top:5px"> <h3 style="color: #1E50AE;font-weight:700;font-size:16px">重要提示</h3> </div>
      <div class="content">
        <span>在继续浏览本公司网站前，请您细阅此重要提示，并向下滚动至本页结尾根据您的具体情况进行选择。</span> <br>
        <span>在继续浏览本公司网站前，请您确认您或您所代表的机构是一名“合格投资者”。“合格投资者”是指：（1）根据中华人民共和国境内法律法规，尤其是中国证监会《私募投资基金监督管理暂行办法》所规定的“合格投资者”，或（2）根据中华人民共和国香港特别行政区《证券及期货条例》所定义的“专业投资者”，或（3）根据美国的证券和投资法规所定义的“合格买方”或“有资格投资者”，或（4）其他任何国家和地区的证券和投资法规所规定的有资格投资于对冲基金或类似的集合理财计划的专业投资者。如您或您所代表的机构对自身是否属于“合格投资者”存有疑问的，请咨询您或您所代表机构聘请的律师、法律顾问或其他法律专业人员。</span>
        <span>您若继续阅览本网页所载资料，将被视为您声明及保证为“合格投资者”，并将遵守对您适用的司法区域的有关法例及法规。</span><br>
        <span>本网站所载的资料仅供参考，并不构成广告或分销、销售要约，或招揽买入任何证券、基金或其他投资工具的邀请或要约。</span><br>
        <span>投资涉及风险，投资者应详细审阅基金的发售文件以获取进一步资料及了解有关投资于新兴市场所涉及之风险因素，并寻求适当的专业投资和税务咨询意见。基金的股份或单位价格及其收益可升可跌，而过往的基金表现数据并不预示基金未来的表现。 本网站所提供的资料并非投资建议或税务咨询意见，投资者不应依赖本网站所提供的资料作出投资决策。</span><br>
        <span>富邦瑞锦公司或其关联公司、董事、监事、高级管理人员及任何雇员不对本网站所提供资料的准确性、充足性或完整性作出任何保证，也不对于本网站内所提供资料之任何错误或遗漏承担任何法律责任。您在任何情况下均不得复印、复制或再分发本网站资料的全部或其任何部分。</span><br>
        <span>本网站所刊载的所有资料富邦瑞锦公司可予以更改或修订而毋须前事通知，富邦瑞锦公司并不承诺定期更新本网页。</span><br>
        <span>与本网站所载资料有关的所有版权、专利权、知识产权及其他产权均为富邦瑞锦公司或其关联公司所有。本公司概不向浏览该资料的人士发出、转让或以任何方式转移任何种类的权利。</span>
        <div style="display:flex;justify-content:space-between;margin-top:15px" class="pc">
          <div @click="setSure" class="btn1">我确认我或我所代表的机构是一名“合格投资者”，而且同意接受上述所有条件和条款。</div>
          <div @click="closeModal" class="btn2">我或我所代表的机构不是一名“合格投资者”，或者我/我们不同意接受上述所有条件和条款。</div>
        </div>
        
        </div>
        <div class="mobie footer-wrapper">
          <div @click="setSure_mb" class="btn3">我同意</div>
           <span style="color:red" v-if="noChecked">*请选择确认按钮</span><br>
            <input type="checkbox" id="sure" name="sure" v-model="checked" @change="checkChange">
            <label for="sure">我确认我或我所代表的机构是一名“合格投资者”，而且同意接受上述所有条件和条款。若否请关闭页面停止浏览</label>
        </div>
    </div> 
  </div>
</template>

<script>
import Home from './views/Home.vue'
import HeaderBar from './components/HeaderBar.vue'
import FooterBar from './components/FooterBar.vue'
export default {
  name: 'App',
  data(){
    return{
      showLeftMenu:false,
      isShow:true,
      checked:false,
      noChecked:false
    }
  },
  created(){
    document.body.style.overflow = 'hidden'
    let isShowModal = localStorage.getItem('isShowModal')
    if(isShowModal === 'false'){
      this.isShow = false
      document.body.style.overflow = 'auto'
    }
  },
  methods:{
    cloeLeftMenu(){
      this.$refs.header.closeLeftMenu()
    },
    setSure(){
      this.isShow = false
      document.body.style.overflow = 'auto'
      localStorage.setItem('isShowModal',false)
    },
    closeModal(){
      // this.isShow = false
      this.$confirm({
        title: '温馨提示',
        content: '页面即将关闭，确认关闭页面?',
        zIndex:99999,
        maskClosable:false,
        okText:'确定',
        cancelText:'取消',
        onOk() {
          location.href= 'https://www.baidu.com'
        },
        onCancel() {},
      });      
    },
    setSure_mb(){
      if(this.checked){
        this.isShow = false
        document.body.style.overflow = 'auto'
        localStorage.setItem('isShowModal',false)
      }else{
        this.noChecked = true
      }
    },
    checkChange(e){
      if(e.target.checked){
        this.noChecked = false
      }
    }
  },
  components: {
    Home,
    HeaderBar,
    FooterBar
  }
}
</script>

<style>
*{
  margin: 0;
  padding: 0;
}
html,body{
  font-family: "Source Han Sans CN","Arial","Microsoft YaHei","sans-serif";
  height: 100%;
}
body {
    color: #333;
    font-size: 13px;
    font-family: "Source Han Sans CN","Arial","Microsoft YaHei","sans-serif";
}
a{
  text-decoration: none;
  color: #fff;
}
ul, li {
    list-style: none;
    margin: 0;
    padding: 0;
}
input, textarea, select {
    color: #333;
    margin: 0;
    padding: 0;
    font-size: 12px;
    font-family: Arial;
    vertical-align: middle;
}
input:focus,textarea:focus {
    outline: none;
}
.clearboth:after {
    display: block;
    clear: both;
    content: "";
    overflow: hidden;
    height: 0;
}
h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
    font-weight: normal;
}
img {
    max-width: 100%;
    vertical-align: top;
}

body::-webkit-scrollbar{
  width:6px;
  height:6px;
}
body::-webkit-scrollbar-track{
  background: rgb(239, 239, 239);
  border-radius:2px;
}
body::-webkit-scrollbar-thumb{
  background: #bfbfbf;
}
.mask{
  background-color: rgb(51, 51, 51);
  position: fixed;
  inset: 0px;
  opacity: 0.8;
  z-index: 1000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.modal{
  width: 1000px;
  position: absolute;
  z-index: 1300;
  left: 50%;
  top:70px;
  transform: translateX(-50%);
  background-color: #ecf1f9;
  border-radius: 7px;
  border: solid 0px rgba(0,0,0,0.4);
  box-shadow: 0px 0px 12px 0px rgba(0,0,0,0.6);
}
.modal .content{
  padding: 0px 20px 15px 20px;
  font-size: 12.5px;
  font-family:  Arial, SimHei, 黑体;
}
.modal .content span{
  line-height: 1.6;
}
.modal .btn1{
  width: 40%;
  background-color:#1E50AE ;
  color: white;
  padding: 10px 55px;
  border-radius: 5px;
  cursor: pointer;
}
.modal .btn2{
  width: 40%;
  background-color: #c5c5c5;
  color: white;
  padding: 10px 55px;
  border-radius: 5px;
  cursor: pointer;
}

.mobie{
  display: none;
}

 
@media (max-width: 1000px) {
  .pc{
    display: none !important;
  }
  .mobie{
    display: block;
  }
 .modal .content{
    height: 50vh;
    overflow: auto;
  }
 .modal .footer-wrapper{
    padding: 0px 20px 15px 20px;
    font-size: 12.5px;
    font-family:  Arial, SimHei, 黑体;
  }
  .modal{
    width: 90%;
  }
 .modal .btn3{
    width: 80px;
    text-align: center;
    background-color:#1E50AE ;
    color: white;
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;
    margin-top:5px ;
  }
}

</style>
