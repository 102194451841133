<template>
  <div>
    <div class="bg">

    </div>
    <div class="content-wrapper">
      <div class="content">
        <div>
          <div style="font-size: 25px;line-height: 30px;"> <span>联系我们</span> </div>
          <div style="font-size: 13px;line-height: 30px;"> <span>Contact</span> </div>
        </div>
        <div class="content2">
          <div style="font-size: 14px; line-height: 25px;">
              <!-- 公司注重价值与成长双重驱动，以基本面为主，择时为辅，顺势而为，严控回撤，力求创造长期稳定增长的业绩。 -->
          </div>
          <div style="line-height: 36px;">
            <span style="color: rgb(119, 119, 119); font-size: 14px;">
              联系我们：座机: 0571-88622507 <span class="blank"></span>
              地址：浙江省杭州市上城区顺福商务中心1幢1106室<span class="blank"></span>
              邮箱：fbrj@fbridgefund.com
            </span>
          </div>
          <div style="line-height: 36px;"><span style="font-size: 14px;"><span style="color: rgb(43, 43, 43);"><b>——</b></span></span></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default{
  name:'ContactUs'
}
</script>
<style scoped>
.bg {
    background-image: url(https://fbrj-hz-01.oss-cn-hangzhou.aliyuncs.com/assets/concat-bg1.jpg);
    background-size: cover;
    background-position: 50% 50%;
    padding-bottom: 23%;
}
.content-wrapper{
  padding-left: 4%;
  padding-bottom: 5vw;
  padding-right: 4%;
  padding-top: 4.6vw;
}
.content2{
  white-space: pre-wrap;
}
.blank{
  display: inline-block;
  width: 20px;
}
@media (max-width: 768px) {
  .bg{
    background-image: url(https://fbrj-hz-01.oss-cn-hangzhou.aliyuncs.com/assets/concat-bg1-m.jpg);
    padding-bottom: 33vh;   
  }
  .blank{
    display: block;
    width: 20px;
  }
  .content-wrapper{
    box-sizing: border-box;
    height: calc(100vh - 0.5rem - 33vh - 62.5px);
  }
}
</style>