import VueRouter from 'vue-router'
import AboutUs from "@/views/AboutUs"
import JoinUs from "@/views/JoinUs"

import Fund from "@/views/Fund"
import ContactUs from "@/views/ContactUs"
import store from '../store'

import {getUserInfo}  from '../api/user'

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch(err => err)
}


const createRouter = () => new VueRouter({
  mode: 'history', // require service support
  scrollBehavior: () => ({ y: 0 }),
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import("@/views/Home")
    },    
    {
      path: '/login',
      name: 'login',
      component: () => import("@/views/Login")
    },
    {
      path: '/aboutus',
      name: 'aboutus',
      component: AboutUs
    },  
    {
      path: '/joinus',
      name: 'joinus',
      component: JoinUs
    },
    {
      path: '/contactus',
      name: 'contactus',
      component: ContactUs
    }, 
    {
      path: '/jobdetail',
      name: 'jobdetail',
      component: () => import("@/views/JobDetail")
    },          
    {
      path: '/fund',
      name: 'fund',
      component: Fund
    },    
    {
      path: '/fundDetail',
      name: 'fundDetail',
      component: () => import("@/views/FundDetail")
    },  
    {
      path: '/forget',
      name: 'forget',
      component: () => import("@/views/Forget")
    },
    {
      path: '/print_announce',
      name: 'print_announce',
      component: () => import("@/views/Manage/PrintAnnouncePage"),
      meta: { havefooter: false, haveheader: false},
    },         
    {
      path: '/personal',
      name: 'personal',
      component: () => import("@/views/Personal"),
      redirect: '/personal/all',
      meta: { havefooter: false },
      children: [
        {
          path: 'all',
          name: 'all',
          component: () => import("@/views/personal/AllProduct"),
          meta: { havefooter: false },
        },
        {
          path: 'contractual',
          name: 'contractual',
          component: () => import("@/views/personal/ContractualType"),
          meta: { havefooter: false },
        },  
        {
          path: 'hold',
          name: 'hold',
          component: () => import("@/views/personal/MyHold"),
          meta: { havefooter: false },
        },  
        {
          path: 'redeem',
          name: 'redeem',
          component: () => import("@/views/personal/MyRedeem"),
          meta: { havefooter: false },
        },  
        {
          path: 'bonus',
          name: 'bonus',
          component: () => import("@/views/personal/MyBonus"),
          meta: { havefooter: false },
        }, 
        {
          path: 'myinfo',
          name: 'myinfo',
          component: () => import("@/views/personal/MyInfo"),
          meta: { havefooter: false },
        },   
        {
          path: 'certification',
          name: 'certification',
          component: () => import("@/views/personal/Certification"),
          meta: { havefooter: false },
        }, 
        {
          path: 'level',
          name: 'level',
          component: () => import("@/views/personal/Level"),
          meta: { havefooter: false },
        }, 
        {
          path: 'changepwd',
          name: 'changepwd',
          component: () => import("@/views/personal/Changepwd"),
          meta: { havefooter: false },
        },                                                               
      ]
    },   
    {
      path: '/manage',
      name: 'manage2',
      component: () => import("@/views/Manage"),
      children: [
        {
          path: 'task_detail',
          name: 'task_detail',
          component: () => import("@/views/MB_Manage/TaskManage_MB/TaskDetail"),
          meta: { havefooter: false },
        },
        {
          path: 'task_edit_page',
          name: 'task_edit_page',
          component: () => import("@/views/MB_Manage/TaskManage_MB/TaskEditPage"),
          meta: { havefooter: false },
        },   
      ]
    },   
    {
      path: '*',
      component: () => import("@/components/404")  
    }
  ],
})
let  allAsyncRoutes = [
  {
    path: '/manage',
    name: 'manage',
    component: () => import("@/views/Manage"),
    // redirect: '/manage/investorinfo',
    meta: { havefooter: false },
    children: [
      {
        path: 'investorinfo',
        name: 'customer_list',
        component: () => import("@/views/Manage/InvestorInfo"),
        meta: { havefooter: false },
      },
      {
        path: 'navinput',
        name: 'fund_list',
        component: () => import("@/views/Manage/NavInput"),
        meta: { havefooter: false },
      },
      {
        path: 'employeemanage',
        name: 'user_list',
        component: () => import("@/views/Manage/EmployeeManage"),
        meta: { havefooter: false },
      },
      {
        path: 'transactionrecords',
        name: 'transactionrecords',
        component: () => import("@/views/Manage/TransactionRecords"),
        meta: { havefooter: false },
      },
      {
        path: 'taskmanage',
        name: 'task_list',
        component: () => import("@/views/Manage/TaskManage"),
        meta: { havefooter: false },
      },         
    ]
  }
]
// function filterAsyncRoutes(allAsyncRoutes, routeNames) {
//   for(let route of allAsyncRoutes){
//     if(route.children){
//       route.children = filterAsyncRoutes(route.children,routeNames)
//     }else{
//       return allAsyncRoutes.filter(r => routeNames.includes(r.name)) 
//     }
//   }
// }
function filterAsyncRoutes(routes, names) {
  return routes
    .filter(route => names.includes(route.name) || (route.children && route.children.length > 0))
    .map(route => {
      if (route.children) {
        route.children = filterAsyncRoutes(route.children, names)
      }
      return route
    })
}

const router = createRouter()

router.beforeEach(async (to, from, next) => {
  //token校验
  let token = store.state.token
  if (token) {
    if(!store.state.userInfo.real_name){
      let res = await getUserInfo()
      if(res.code == 200){
        store.dispatch('save_userInfo',res.data)
        if(res.data.privilege && res.data.privilege.length>0){
          let routeNames = res.data.privilege.map((item) => item.value)
          let asyncRoutes = filterAsyncRoutes(allAsyncRoutes, routeNames)
          // console.log(allAsyncRoutes)
          router.addRoutes([...asyncRoutes])
          next({ ...to, replace: true })
        }
      }
    }
    //代表登录了或者之前登录过
    if (to.path === '/login') {
      //登录过了，又想去登录页，直接跳转到首页
      next('/personal')
    } else if(to.path === '/manage') {
      if(allAsyncRoutes[0].children && allAsyncRoutes[0].children.length>0){
        let name = allAsyncRoutes[0].children[0].name
        next({name})
      }else{
        next()
      }
    }else{
      next()
      // if (hasRoles) {
      //   //此时代表登录了，去的不是登录页，用户信息存在,直接无条件放行
      //   next()
      // } else {
      //   //此时代表登录了，去的不是登录页，用户信息不存在那我们要根据token发请求获取用户的真证信息

      // }
    }
  } else {
    //代表用户没登录或者之前也没登录过
    if(to.path.includes('personal')){
      next('/login')
    }else{
      next()
    }
  }
})

export default router