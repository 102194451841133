<template>
  <div>
    <div class="bg">

    </div>
    <div class="invest">
        <div class="title">
            <div style="line-height: 25px;">
                <span style="color:rgb(68,68,68)">投资领域</span>
            </div>
            <div style="line-height: 25px;">
                <span style="color:#a5a5a5;font-size: 13px;">Investment Field</span>
            </div>   
        </div>
        <div class="content">
            <div style="line-height: 36px;">
                <!-- <p style="line-height: 27px; color: rgb(68, 68, 68); font-family: arial, &quot;microsoft yahei&quot;, helvetica, sans-serif;">我们公司的投资领域目前有这些：</p> -->
                <p style="color: rgb(68, 68, 68); font-family: arial, &quot;microsoft yahei&quot;, helvetica, sans-serif;">专注A股市场股票投资，不断拓展多资产策略构建和运作边际，实现资产风险收益科学配置。</p>
            </div>
        </div>
    </div>
    <div class="product_wrapper">
        <div style="display: flex;flex-wrap: wrap;align-items: stretch;justify-content: center;">
            <div class="w">
                <div class="product">
                    <div class="product_pic_wrap">
                        <div class="product_pic_item pic1">
                        
                        </div>
                    </div>
                    <div style="margin-bottom: 5px;margin-top: 15px;text-align: center;">
                        <!-- 富邦瑞锦系列、富邦萱劲系列 -->
                    </div>       
                    <div style="margin-bottom: 5px;margin-top: 15px;text-align: center;">
                        <!-- 在回撤可控的情况下。长期获取更可观的收益 -->
                    </div>                      
                </div>
            </div>
            <div class="w">
                <div class="product">
                    <div class="product_pic_wrap">
                        <div class="product_pic_item pic2">
                        
                        </div>
                    </div>
                    <div style="margin-bottom: 5px;margin-top: 15px;text-align: center;">
                        <!-- 股指CTA策略、富邦股指CTA系列 -->
                    </div>  
                    <div style="margin-bottom: 5px;margin-top: 15px;text-align: center;">
                        <!-- 以获取绝对收益为目标、回撤低，表现稳健 -->
                    </div>                         
                </div>
            </div>
        </div>
        <div style="text-align:center;margin-top:30px">
            信息披露入口： <a href="https://www.fbridgefund.com/login" style="color:#1890ff;font-family: arial, &quot;microsoft yahei&quot;, helvetica, sans-serif;font-size:18px"> https://www.fbridgefund.com/login</a>
        </div>
    </div>
  </div>
</template>

<script>
  export default {
    data(){
        return{

        }
    },
  }
</script>

<style scoped>
.bg {
    background-image: url(https://fbrj-hz-01.oss-cn-hangzhou.aliyuncs.com/assets/fund-bg1.png);
    background-size: cover;
    background-position: 50% 50%;
    padding-bottom: 23%;
}
.invest{
    padding-left: 17%;
    padding-bottom: 2vw;
    padding-right: 17%;
    padding-top: 4vw;
    display: flex;
    flex-wrap: wrap;
}
.title{
    width: 25%;
    animation: 0s ease 0s 1 normal none running none;
}
.content{
    width: 75%;
    animation: 0s ease 0s 1 normal none running none;
}
.title:first-child{
    font-size: 25px;
}
.content p {
    font-size: 15px;
}

.product_pic_wrap{
    position: relative;
    padding-bottom: 100%;
    overflow: hidden;
}
.product_pic_item {
    position: absolute;
    width: 100%;
    height: 100%;
    cursor: pointer;
    background-size: cover;
    background-position: 50%;
    background-repeat: no-repeat;
    transition: all .36s ease;
}
.pic1{
    background-image: url(https://fbrj-hz-01.oss-cn-hangzhou.aliyuncs.com/assets/fund-bg2.png);
}
.pic2{
    background-image: url(https://fbrj-hz-01.oss-cn-hangzhou.aliyuncs.com/assets/fund-bg3.png);
}
/* .pic3{
    background-image: url(https://fbrj-hz-01.oss-cn-hangzhou.aliyuncs.com/assets/fund-bg4.png);
} */
.product_wrapper{
    margin-left: 14%;
    margin-right: 14%;
    padding-bottom: 7vw;
}
.w{
    width: calc(33.33% - 20px);
}
.product{
    animation: 0s ease 0s 1 normal none running none;
    padding: 5%;
}
.product:hover{
    box-shadow: 0 0 15px rgba(0,0,0,.1);
}
.product:hover .product_pic_item {
    transform: scale(1.05);
    cursor: pointer;
}

@media (max-width: 768px){
.bg {
    background-image: url(https://fbrj-hz-01.oss-cn-hangzhou.aliyuncs.com/assets/fund-bg1-m.jpg);
    padding-bottom: 33vh;
}
.title,.content{
    width: 100%;
}
.title:first-child{
    font-size: 18px;
}
.content p {
    font-size: 14px;
}
.w{
    width: 50%;
}
.product_wrapper{
    margin-left: 0;
    margin-right: 0;
}
.invest{
    height: 150px;
}
.invest,.product_wrapper{
    padding:3vw 4% 1.3vw 4%;
}
.product_wrapper{
    box-sizing: border-box;
    height: calc(100vh - 0.5rem - 33vh - 150px - 4.3vw - 46.5px);
}
.pic1{
    background-image: url(https://fbrj-hz-01.oss-cn-hangzhou.aliyuncs.com/assets/fund-bg2-m.png);
}
.pic2{
    background-image: url(https://fbrj-hz-01.oss-cn-hangzhou.aliyuncs.com/assets/fund-bg3-m.png);
}
}
</style>