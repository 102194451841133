import axios from 'axios' // 引入axios
import { message } from 'ant-design-vue'
const service = axios.create({
  baseURL: '/api/v1',
  timeout: 99999
})

// http request 拦截器
service.interceptors.request.use(
  config => {
    config.headers['token'] = sessionStorage.getItem('token')
    return config
  }
)

// http response 拦截器
service.interceptors.response.use(
  (response) => {
    const { config } = response;
    if (config && config.responseType && config.responseType == 'arraybuffer') {
      return response
    }
    response = response.data
    return response
  },
  (error) => {
    if (error && error.response && error.response.status === 401) {
      sessionStorage.clear()
      window.location = '/login'
      message.error('登录失效，请重新登录')
    }
    if (error && error.response && error.response.status === 429) {
      message.error('请求过于频繁，请稍后再试')
    }
    return Promise.reject(error)
  }
)
export default service
