<template>
  <div>
    <div class="footer_wrapper">
      <div class="footer1">
        <div class="marquee-container_pc" v-if="$route.name == 'login'">
          <div class="marquee-box_pc">
            <p>
              尊敬的投资者：为确保您能及时了解我司发布的信息，特此再次告知我司信息披露平台。我司披露的净值，以微信小程序“招商证券-投资人服务平台”和我司官方网站“https://www.fbridgefund.com/login”二者披露的净值核对一致为准；我司披露的公告信息，以我司官方网站“https://www.fbridgefund.com/login”披露的公告信息为准。如对信息披露有疑问，请联系我司工作人员。
            </p>
          </div>
        </div>
        <div v-else style="height:28px">
        </div>  
        <router-link to="/">首页</router-link>
        <router-link to="/aboutus" >关于我们</router-link>
        <router-link to="/fund">基金产品</router-link>
        <router-link to="/joinus">加入我们</router-link>
        <router-link to="/contactus">联系我们</router-link>
          <!-- <div class="link_wrapper">
            <a href="javascript:;" class="wx">
            <div class="wx_img">
              <img src="../assets/wx.jpg" alt="">
            </div>
            </a>
            <a href="javascript:;" target="_blank" class="wb"></a>
          </div> -->

      </div>
      <div class="footer2">
        <div class="marquee-container_mb" v-if="$route.name == 'login'">
          <div class="marquee-box_mb">
            <p>
              尊敬的投资者：为确保您能及时了解我司发布的信息，特此再次告知我司信息披露平台。我司披露的净值，以微信小程序“招商证券-投资人服务平台”和我司官方网站“https://www.fbridgefund.com/login”二者披露的净值核对一致为准；我司披露的公告信息，以我司官方网站“https://www.fbridgefund.com/login”披露的公告信息为准。如对信息披露有疑问，请联系我司工作人员。
            </p>
          </div>
        </div>        
        <span>All Rights Reserved FBRIDGE FUND</span> 
        <span class="blank"></span>
        <a href="https://beian.miit.gov.cn/" target="_blank">浙ICP备2023012963号-1</a>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    mounted(){
      // let containerWidth = document.querySelector('.marquee-container_pc').offsetWidth 
      // let boxWidth = document.querySelector('.marquee-container_pc .marquee-box_pc').offsetWidth 
      // let duration = (boxWidth * 2 + containerWidth) / 150 + 's'
      // document.querySelector('.marquee-container_pc .marquee-box_pc').style.cssText = 'animation-duration:' + duration
      // // 手机版写死
      // document.querySelector('.marquee-container_mb .marquee-box_mb').style.cssText = 'animation-duration:' + '25s'
    }
  }
</script>

<style scoped>
.footer_wrapper{
  text-align: center;
}
.footer1{
  padding: 28px 0;
  padding-top: 0;
  background: #292929;;
}
.footer1 a {
    font-size: 14px;
    color: #cccccc;
    margin-left: 115px;
    transition: all 0.3s ease;
}
.link_wrapper{
  display: inline-block;
}
.footer1 .wx{ 
  background: url(../assets/weixin1.png) no-repeat;
  background-size: 100%;

}
.footer1 .wx:hover {
    background: url(../assets/weixin2.png) no-repeat;
    background-size: 100%;
}
 .wx_img {
    display: none;
    position: absolute;
    width: 80px;
    bottom: 35px;
    left: 50%;
    margin-left: -40px;
}
 .footer1 .wb {
    background: url(../assets/weibo1.png) no-repeat;
    background-size: 100%;
}
.footer1 .wb:hover {
    background: url(../assets/weibo2.png) no-repeat;
    background-size: 100%;
}
.wx,.wb{
  display: inline-block;
  vertical-align: middle;
  width: 35px;
  height: 35px;
  /* margin-left: 30px; */
  /* position: relative; */
  transition: all 0.3s ease;
}
.footer_wrapper .footer2{
  line-height: 60px;
  background: #222222;
}
.footer2,.footer2 a{
  color:rgb(102, 102, 102)
}
.footer2 a:hover{
  color: #1E50AE;
}
.footer2 .blank{
  display: inline-block;
  width: 20px;
}


* {
  margin: 0;
  padding: 0;
}
.marquee-container_pc {
  position: relative;
  width: 100%;
  height: 50px;
  line-height: 50px;
  overflow: hidden;
  font-size: 15px;
}

.marquee-box_pc {
  position: absolute;
  display: inline-block;
  color: #fff;
  white-space: nowrap;
  animation: marquee 25s linear infinite;
}


.marquee-container_mb {
  position: relative;
  width: 100%;
  height: 30px;
  line-height: 30px;
  overflow: hidden;
  display: none;
  font-size: 15px;
}

.marquee-box_mb {
  position: absolute;
  display: inline-block;
  color: #fff;
  white-space: nowrap;
  animation: marquee 25s linear infinite;
}
@keyframes marquee {
  0% {
    left: 100%;
  }
  100% {
    left: 0%;
    transform: translateX(-100%);
  }
}


@media (max-width: 768px) {
  .footer1{
    display: none;
  }
  .footer1 a {
    margin-left:0.2rem;
}
  .footer1 .link_wrapper {
    margin-top:0.2rem;
    display: block;
  }
  .footer_wrapper .footer2{
    line-height: initial;
    padding: 10px 0;
    /* 显示指定，本地的全局样式和antdesign的样式干扰了 */
    font-size: 14px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  }
  .footer2 .blank{
    display: block;
    height: 5px;
  }
  .marquee-container_mb{
    display: block;
  }
}

</style>